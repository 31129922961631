import React, { useEffect, useState } from "react";
import axios from 'axios'
import SSRProvider from 'react-bootstrap/SSRProvider';
import { useDispatch } from "react-redux";
import { isMobile } from "../utils";
import PaymentService from "../services/PaymentService";
import Dialog from "../components/Dialog";
import Loader from "../components/Loader";
import Footer from "../components/Footer";
import {
  actionTypes,
} from "../store/Dialog/type";

const paymentService = new PaymentService();

// styles
const pageStyles = {
  magin: 0,
  padding: 0,
  marginBottom: '-181px'
}

const pageMStyles = {
  marginBottom: 0,
  height: 'calc(100vh + 50px)',
  padding: 0,
}

const headerStyles = {
  height: '95px',
  background: '#1F3E78',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '40px',
}

const signatureStyle = {
  width: '100%',
  height: 'calc(100vh - 45px)'
}

const logoStyle = {
  left: '60px',
  width: '240px',
  height: '54px',
  top: '85px',
}
// markup
const DocumentSignPage = ({ location }) => {
  const [xodoDocument, setXodoDocument] = useState()
  const dispatch = useDispatch();
  const [done, setDone] = useState(false)
  const params = new URLSearchParams(location.search);
  const document_hash = params.get("document_hash");

  useEffect(() => {
    if (document_hash) {
      sessionStorage.removeItem('newsign');
      getXodoDocument()
    }
  }, [document_hash])

  useEffect(() => {
    const interval = setInterval(() => {
      if (sessionStorage.getItem('completed') !== '1') {
        if (document_hash) {
          getXodoDocument()

        }
      } else if (sessionStorage.getItem('completed') === '1') {
        clearInterval(interval)
        documentCompeleted()
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const getXodoDocument = async () => {
    const _xodoDocument = await axios.get(`https://api.eversign.com/document?access_key=f866769843fe60985dd82db6e8f655b9&business_id=712865&document_hash=${document_hash}`);
    if (_xodoDocument && _xodoDocument.data.document_hash) {
      setXodoDocument(_xodoDocument.data)
      if (_xodoDocument.data.is_completed === 1) {
        sessionStorage.setItem('completed', '1')
      } else {
        sessionStorage.setItem('newsign', '1')
      }
    }

  }

  const documentCompeleted = async () => {
    sessionStorage.removeItem('completed');
    if (sessionStorage.getItem('newsign') === '1') {
      sessionStorage.removeItem('newsign');
      const payment = await paymentService.updatePaymentProcess({
        document_hash,
        step: 'dialog_submitted',
        opal_suffix: '',
      })
      dispatch({ type: actionTypes.SHOW_DIALOG, value: { show: true, type: 'dialog_submitted_ria', payment } })
      setDone(true);
    } else {
      const payment = await paymentService.updatePaymentProcess({
        document_hash,
        opal_suffix: '',
      })
      dispatch({ type: actionTypes.SHOW_DIALOG, value: { show: true, type: 'dialog_submitted_ria', payment } })
      setDone(true);
    }
  }
  return (
    <SSRProvider>
      <main style={isMobile() ? pageMStyles : pageStyles}>
        <div style={headerStyles}>
          <div style={logoStyle}><img height="54px" src="https://images.prismic.io/opalvest/38c7b4e5-807b-4d67-90cf-6514bb473a0c_ov-knockout-logo-108px-w.png?auto=compress,format" alt="" /></div>
        </div>
        {(!xodoDocument) && <Loader />}
        {(xodoDocument && !done) && <iframe src={xodoDocument?.signers[0].embedded_signing_url} style={signatureStyle} />}
        {done && <Dialog />}
      </main>
      <Footer />
    </SSRProvider>
  )
}

export default DocumentSignPage
